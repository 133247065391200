import { captureException } from "~/plugins/sentry";

const s = window.slabsGlobal;

type SlabsFunctionMap = {
  [K in keyof Window["slabsGlobal"]]: Window["slabsGlobal"][K] extends SlabsFn<
    infer Params,
    infer Return
  >
    ? (...args: Params) => Return
    : never;
};

// Extracts the parameters types from a function signature in SlabsFunctionMap
type ExtractParams<K extends keyof SlabsFunctionMap> =
  SlabsFunctionMap[K] extends (...args: infer P) => any ? P : never;

// Extracts the return type from a function signature in SlabsFunctionMap
type ExtractReturn<K extends keyof SlabsFunctionMap> =
  SlabsFunctionMap[K] extends (...args: any[]) => infer R ? R : never;

export const w2 = <K extends keyof SlabsFunctionMap>(fnName: K) => {
  const slabsFn: SlabsFn<ExtractParams<K>, ExtractReturn<K>> | undefined =
    s && fnName in s ? (s[fnName] as any) : undefined;

  return w(slabsFn, fnName);
};

const tryParseJson = (v: string) => {
  try {
    return JSON.parse(v);
  } catch (error) {
    console.error(error);
    console.error(`Error parsing JSON: ${v}`);

    // You can choose to return or throw an error here based on your requirements
    return undefined;
  }
};

export const w = <P extends any[], T>(
  slabsFn: SlabsFn<P, T> | undefined,
  fnName: string,
) => {
  return (...args: P) => {
    return new Promise<T>((resolve) => {
      if (!slabsFn) {
        captureException(`!window.slabsGlobal.${fnName}`);
        return undefined;
      }

      return slabsFn(
        (v) => {
          // sometimes the response is undefined
          if (!v) {
            return resolve(undefined as T);
          }

          let parsed = tryParseJson(v);

          // sometimes the response is double json encoded (obs source settings)
          if (typeof parsed === "string") {
            parsed = tryParseJson(parsed);
          }

          if ("error" in parsed) {
            console.error(`args = ${JSON.stringify(args)}`);
            captureException(
              `window.slabsGlobal.${fnName} error: ${parsed.error}`,
            );
          }

          // console.log(`resolve with`, parsed);
          resolve(parsed);
        },
        ...args,
      );
    });
  };
};

const dock = {
  queryAll: w2("dock_queryAll"),
  setURL: w2("dock_setURL"),
  executeJavascript: w2("dock_executeJavascript"),
  toggleDockVisibility: w2("dock_toggleDockVisibility"),
  destroyBrowserDock: w2("dock_destroyBrowserDock"),
  newBrowserDock: w2("dock_newBrowserDock"),
  setArea: w2("dock_setArea"),
  resize: w2("dock_resize"),
  swap: w2("dock_swap"),
  setTitle: w2("dock_setTitle"),
  saveSlabsBrowserDocks: w2("dock_saveSlabsBrowserDocks"),
  // deprecated
  // rename: w2('dock_rename'),
};

const qt = {
  getMainWindowGeometry: w2("qt_getMainWindowGeometry"),
  set_js_on_click_stream: w2("qt_set_js_on_click_stream"),
  click_stream_button: w2("qt_click_stream_button"),
};

const win = {
  toggleUserInput: w2("win_toggleUserInput"),
  restartOBS: w2("win_restartOBS"),
};

const browser = {
  resizeBrowser: w2("browser_resizeBrowser"),
  bringToFront: w2("browser_bringToFront"),
  setWindowPosition: w2("browser_setWindowPosition"),
  setAllowHideBrowser: w2("browser_setAllowHideBrowser"),
  setHiddenState: w2("browser_setHiddenState"),
};

const fs = {
  downloadZip: w2("fs_downloadZip"),
  downloadFile: w2("fs_downloadFile"),
  installFont: w2("fs_installFont"),
  readFile: w2("fs_readFile"),
  deleteFiles: w2("fs_deleteFiles"),
  dropFolder: w2("fs_dropFolder"),
  queryDownloadsFolder: w2("fs_queryDownloadsFolder"),
  getLogsReportString: w2("fs_getLogsReportString"),
};

const tabs = {
  queryAll: w2("tabs_queryAll"),
  // registerMsgReceiver: w2("tabs_registerMsgReceiver"),
  registerMsgReceiver: (callback: (payload: string, uid: number) => any) => {
    if (!s) {
      console.warn("tabs_registerMsgReceiver not found");
      return;
    }

    s.tabs_registerMsgReceiver(function (payload, uid) {
      // console.log(`received message from tab in code`, { payload, uid });
      callback(payload, uid);
    });
  },
  createWindow: w2("tabs_createWindow"),
  executeJs: w2("tabs_executeJs"),
  hideWindow: w2("tabs_hideWindow"),
  showWindow: w2("tabs_showWindow"),
  getIsWindowHidden: w2("tabs_getIsWindowHidden"),
  getWindowCefId: w2("tabs_getWindowCefId"),
  resizeWindow: w2("tabs_resizeWindow"),
  loadUrl: w2("tabs_loadUrl"),
  destroyWindow: w2("tabs_destroyWindow"),
  sendStringToTab: w2("tabs_sendStringToTab"),
  setIcon: w2("tabs_setIcon"),
  setTitle: w2("tabs_setTitle"),
};

const obs = {
  source_create: w2("obs_source_create"),
  source_destroy: w2("obs_source_destroy"),
  set_stream_settings: w2("obs_set_stream_settings"),
  get_stream_settings: w2("obs_get_stream_settings"),
  set_current_scene: w2("obs_set_current_scene"),
  get_current_scene: w2("obs_get_current_scene"),
  create_scene: w2("obs_create_scene"),
  scene_add: w2("obs_scene_add"),
  scene_get_sources: w2("obs_scene_get_sources"),
  query_all_sources: w2("obs_query_all_sources"),
  enum_scenes: w2("obs_enum_scenes"),
  source_get_properties_json: w2("obs_source_get_properties_json"),
  source_get_settings_json: w2("obs_source_get_settings_json"),
  source_set_settings_json: w2("obs_source_set_settings_json"),
  get_scene_collections: w2("obs_get_scene_collections"),
  // obs_get_current_scene_collection: w2('obs_get_current_scene_collection'),
  // obs_set_current_scene_collection: w2('obs_set_current_scene_collection'),
  add_scene_collection: w2("obs_add_scene_collection"),
  sceneitem_set_pos: w2("obs_sceneitem_set_pos"),
  sceneitem_set_rot: w2("obs_sceneitem_set_rot"),
  sceneitem_set_crop: w2("obs_sceneitem_set_crop"),
  sceneitem_set_scale: w2("obs_sceneitem_set_scale"),
  // obs_sceneitem_set_scale_filter: w2('obs_sceneitem_set_scale_filter'),
  // obs_sceneitem_set_blending_mode: w2('obs_sceneitem_set_blending_mode'),
  // obs_sceneitem_set_blending_method: w2('obs_sceneitem_set_blending_method'),
  // obs_sceneitem_get_pos: w2('obs_sceneitem_get_pos'),
  // obs_sceneitem_get_rot: w2('obs_sceneitem_get_rot'),
  // obs_sceneitem_get_crop: w2('obs_sceneitem_get_crop'),
  // obs_sceneitem_get_scale: w2('obs_sceneitem_get_scale'),
  // obs_sceneitem_get_scale_filter: w2('obs_sceneitem_get_scale_filter'),
  // obs_sceneitem_get_blending_mode: w2('obs_sceneitem_get_blending_mode'),
  // obs_sceneitem_get_blending_method: w2('obs_sceneitem_get_blending_method'),
  // obs_source_get_dimensions: w2('obs_source_get_dimensions'),
  canvas_get_dimensions: w2("obs_canvas_get_dimensions"),
  bring_front: w2("obs_bring_front"),
  toggle_hide_self: w2("obs_toggle_hide_self"),
  add_transition: w2("obs_add_transition"),
  set_current_transition: w2("obs_set_current_transition"),
  // obs_remove_transition: w2('obs_remove_transition'),
  // obs_transition_get_settings_json: w2('obs_transition_get_settings_json'),
  transition_set_settings_json: w2("obs_transition_set_settings_json"),
  frontend_streaming_active: w2("obs_frontend_streaming_active"),
};

const web = {
  startServer: w2("web_startServer"),
  stopServer: w2("web_stopServer"),
  launchOSBrowserUrl: w2("web_launchOSBrowserUrl"),
  getAuthToken: w2("web_getAuthToken"),
  clearAuthToken: w2("web_clearAuthToken"),
};

const sl = {
  getVersionInfo: w2("sl_getVersionInfo"),
};

const Bindings = {
  dock,
  qt,
  win,
  browser,
  fs,
  obs,
  web,
  sl,
  tabs,
};

export const setStreamSettings = ({
  key,
  url,
}: {
  key: string;
  url: string;
}) => {
  console.log("setStreamSettings", { key, url });

  if (!key || !url) {
    captureException(`setStreamSettings() empty key OR url`, {
      contexts: {
        exception: {
          key,
          url,
        },
      },
    });
  }

  return Bindings.obs.set_stream_settings(
    "rtmp_custom",
    "RTMP",
    url,
    false,
    "",
    "",
    key,
  );
};

export const openBrowserTo = (url: string, utmSource?: string) => {
  if (!utmSource) {
    utmSource = "path_" + window.location.pathname.slice(1).replace(/\//g, "_");
  }

  const u = new URL(url);

  if (u.host.endsWith("streamlabs.com")) {
    u.searchParams.set("utm_source", utmSource);
    u.searchParams.set("utm_medium", "obs_plugin");
    u.searchParams.set("utm_campaign", "obs_plugin");
  }

  console.log(`opening browser to: ${u.toString()}`);

  if (window.slabsGlobal) {
    Bindings.web.launchOSBrowserUrl(u.toString());
  } else {
    window.open(u.toString());
  }
};

export const createSource = ({
  type,
  name,
  settings,
}: {
  type: SourceTypeId;
  name: string;
  settings: any;
}) =>
  window.slabsGlobal
    ? Bindings.obs.source_create(type, name, JSON.stringify(settings))
    : console.log(`createSource`, { type, name, settings });

export default Bindings;
